<template>
	<v-container v-if="$can('view', 'Professional')" fluid>
		<div v-if="$can('create', 'Professional')" class="text-right">
			<v-btn
				rounded
				color="primary"
				dark
				@click="createProfessional()"
				:loading="loading"
				>+ Create Professional</v-btn
			>
		</div>

		<div v-if="professionals.length > 0" class="mt-4">
			<ProfessionalCard
				v-for="professional in professionals"
				:key="professional.id"
				:professional="professional"
			></ProfessionalCard>
		</div>

		<NoData v-else></NoData>

		<Paginator
			:paginator="paginator"
			:url="getUrl()"
			@update="changePage($event)"
		></Paginator>
	</v-container>
</template>

<script>
import NoData from '../../components/NoData'
import Paginator from '../../components/Paginator'
import ProfessionalCard from './components/ProfessionalCard'
export default {
	components: {
		ProfessionalCard,
		Paginator,
		NoData,
	},
	data() {
		return {
			loading: false,
		}
	},
	created() {
		this.$store.dispatch('professional/getProfessionalListingData')
	},
	// destroyed() {
	// 	this.$store.commit('professional/clearState')
	// },
	computed: {
		professionals: {
			get() {
				return this.$store.state.professional.professionals
			},
			set(value) {
				this.updateProfessionals(value)
			},
		},
		paginator: {
			get() {
				return this.$store.state.professional.paginator
			},
			set(value) {
				this.updatePaginator(value)
			},
		},
	},
	methods: {
		updatePaginator(value) {
			this.$store.commit('professional/updatePaginator', value)
		},
		updateProfessionals(value) {
			this.$store.commit('professional/updateProfessionals', value)
		},
		changePage(data) {
			this.professionals = data.data
			this.paginator = data.paginator
		},
		getUrl() {
			const projectId = this.$store.state.app.projectId
			let url = `/${projectId}/professionals`
			return url
		},
		createProfessional() {
			this.loading = true
			this.$store.commit('professional/clearState')
			this.$router.push({
				name: 'professional.create',
			})
			this.loading = false
		},
	},
}
</script>
