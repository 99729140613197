<template>
	<v-dialog v-model="showDelete" max-width="600px" v-if="showDelete">
		<v-card class="pa-6 mb-0 d-flex flex-column delete-modal">
			<div class="d-flex flex-row align-center">
				<span class="mdi mdi-alert-circle icon mr-2"></span>
				<h1 class="heading">Are you sure you want to delete?</h1>
			</div>
			<div class="d-flex flex-row justify-end">
				<v-btn
					class="mt-4 mr-4"
					outlined
					color="#721c24"
					@click="
						$emit('confirm', {
							delete: true,
							show: false,
						})
					"
					>Delete</v-btn
				>
				<v-btn
					class="mt-4"
					outlined
					color="indigo"
					@click="
						$emit('confirm', {
							delete: false,
							show: false,
						})
					"
					>Cancel</v-btn
				>
			</div>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	props: {
		deleteConfirm: {
			type: Boolean,
			required: true,
		},
		showDelete: {
			type: Boolean,
			required: true,
		},
	},
}
</script>
