<template>
	<v-card class="mx-auto" outlined>
		<v-row class="ma-0 pa-6">
			<v-col cols="2" class="pa-0">
				<div class="img-wrapper">
					<div v-if="professional.banner !== null">
						<v-img :src="professional.banner.publicURL"></v-img>
					</div>
					<div v-else>
						<p>No Image Uploaded</p>
					</div>
				</div>
			</v-col>
			<v-col cols="10" class="pl-5 pa-0">
				<div class="d-flex align-start">
					<h3 class="title" color="#5F615A">
						{{ professional.fullName }}
					</h3>
					<v-spacer></v-spacer>
					<StatusChips
						:is-published="professional.isPublished"
					></StatusChips>
				</div>
				<div>
					<h4 class="font-weight-light heading">
						{{ professional.experience }} years of experience
					</h4>

					<p class="font-weight-light body-2 mb-2 mt-2">
						{{ educationString }}
					</p>
				</div>

				<div v-if="professional.services.length > 0">
					<v-chip
						class="specialization"
						v-for="service in professional.services"
						:key="service.id"
						>{{ service.name }}</v-chip
					>
				</div>
			</v-col>
		</v-row>

		<v-divider></v-divider>
		<!--Action Buttons-->
		<v-card-actions class="px-6 py-4 card-actions">
			<UnoEditButton
				v-if="$can('update', 'Professional')"
				:to="{
					name: 'professional.edit',
					params: { id: professional.id },
				}"
			></UnoEditButton>

			<UnoPublishButton
				v-if="$can('publish', 'Professional')"
				:is-published="professional.isPublished"
				@click.native="toggleStatus(professional)"
			></UnoPublishButton>

			<v-spacer></v-spacer>

			<UnoDeleteButton
				v-if="$can('delete', 'Professional')"
				@confirm="deleteProfessional(professional.id)"
			></UnoDeleteButton>
		</v-card-actions>

		<DeleteAlert
			:delete-confirm="deleteConfirm"
			:show-delete="showDelete"
			@confirm="deleteProfessional($event)"
		></DeleteAlert>
	</v-card>
</template>

<script>
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import StatusChips from '@/components/StatusChips'
// Alerts
import DeleteAlert from '@/components/Alerts/DeleteAlert'

export default {
	components: {
		UnoEditButton,
		StatusChips,
		UnoDeleteButton,
		UnoPublishButton,
		DeleteAlert,
	},
	data() {
		return {
			deleteConfirm: false,
			showDelete: false,
			id: null,
		}
	},
	props: {
		professional: {
			type: Object,
			required: true,
		},
	},
	computed: {
		educationString() {
			return this.professional.qualifications
				.map((e) => e.course)
				.join(', ')
		},
	},
	methods: {
		toggleStatus(professional) {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'post',
				url: `/${projectId}/professionals/toggle-status/${professional.id}`,
			}).then((res) => {
				professional.isPublished = res.data.data.isPublished
			})
		},
		deleteProfessional(id) {
			this.$store.dispatch('professional/deleteProfessional', {
				professionalId: id,
			})
		},
		servicesName(professional) {
			let services = professional.services
			let allServicesName = services.map(function (service) {
				return service.name
			})
			return allServicesName
		},
	},
}
</script>

<style scoped>
.img-wrapper {
	overflow: hidden;
	border: 1px solid transparent;
	max-height: 160px !important;
}

.specialization {
	height: 28px !important;
	line-height: 28px !important;
	margin-right: 5px !important;
}
.professional {
	padding: 20px 0;
}

.doc-info {
	font-size: 16px;
}
.heading {
	color: #1976d2;
}
</style>
