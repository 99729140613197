var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDelete)?_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showDelete),callback:function ($$v) {_vm.showDelete=$$v},expression:"showDelete"}},[_c('v-card',{staticClass:"pa-6 mb-0 d-flex flex-column delete-modal"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',{staticClass:"mdi mdi-alert-circle icon mr-2"}),_c('h1',{staticClass:"heading"},[_vm._v("Are you sure you want to delete?")])]),_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-btn',{staticClass:"mt-4 mr-4",attrs:{"outlined":"","color":"#721c24"},on:{"click":function($event){return _vm.$emit('confirm', {
						delete: true,
						show: false,
					})}}},[_vm._v("Delete")]),_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","color":"indigo"},on:{"click":function($event){return _vm.$emit('confirm', {
						delete: false,
						show: false,
					})}}},[_vm._v("Cancel")])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }